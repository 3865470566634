import React from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import { postData } from "../../Fetch/Axios";
import URLS from "../../Fetch/URLS";

const ExcelUploader = () => {
  const handleFileUpload = (file) => {
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Assuming you want to read the first sheet
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Convert the sheet to JSON
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      const finalData = [];
      let el = jsonData[0];
      let keys = Object.keys(el);

      // validate the field name matches to the first row of the excel
      for (const element of jsonData) {
        let el = { ...element, is_reported_through_1920: true };

        delete el["__rowNum__"];

        finalData.push(el);
      }

      const res = await postData(finalData, URLS.uploadExcel);

      //   message.success("Excel file uploaded and data read successfully!");
    };

    reader.onerror = (error) => {
      message.error("Error reading the Excel file!");
      console.error("File reading error:", error);
    };

    reader.readAsArrayBuffer(file);
    return false; // Prevent upload, as we only need to read the file
  };

  return (
    <Upload
      accept=".xlsx, .xls"
      beforeUpload={handleFileUpload}
      showUploadList={false}
    >
      <Button icon={<UploadOutlined />}>Upload Excel File</Button>
    </Upload>
  );
};

export default ExcelUploader;
