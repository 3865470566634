import React, { useEffect, useReducer } from "react";
import { useState } from "react";
import { getData } from "../Fetch/Axios";
import URLS from "../Fetch/URLS";
import { Image } from "antd";
import { truncateString } from "../utils/commonFunctions";

const TVConfiguration = () => {
  const [lostPersons, setLostPersons] = useState([]);
  const [foundPersons, setFoundPersons] = useState([]);
  const [page, setPage] = useState({ lostPage: 1, foundPage: 1 });
  const [presentingTitle, setPresentingTitle] = useState(
    "खोये हुए व्यक्ति की घोषणा"
  );

  const [presentingDoc, setPresentingDoc] = useState({
    first_name: "First name",
    complaint_number: "F",
    last_name: "Last name",
    name_hindi: "---",
    age: "20",
    attire: "Don't Know",
    lost_date: "Lost Date",
  });

  useEffect(() => {
    const getDetails = async (url, setFun, limit, setPresentingDoc) => {
      url = url + `?limit=${limit}&page=`;

      if (setPresentingDoc) {
        url = url + page.lostPage;
      } else url = url + page.foundPage;

      const details = await getData(url);

      setFun(() => details.docs);

      // if (setPresentingDoc) {
      //   if (details.docs.length) {
      //     setPresentingDoc(() => details.docs[0]);
      //     setPresentingTitle("खोये हुए व्यक्ति की घोषणा");
      //   } else {
      //     setPage((prev) => {
      //       return { ...prev, lostPage: 1 };
      //     });
      //   }
      // } else if (!details.docs.length) {
      //   setPage((prev) => {
      //     return { ...prev, foundPage: 1 };
      //   });
      // }

      if (setPresentingDoc) {
        if (details.docs.length && url.includes("lost")) {
          setPresentingDoc(() => details.docs[0]);
          setPresentingTitle("खोये हुए व्यक्ति की घोषणा");
        } else if (details.pagination.page !== 1) {
          setPage((prev) => {
            return { ...prev, lostPage: 1 };
          });
        }
      } else if (!details.docs.length && details.pagination.page !== 1) {
        setPage((prev) => {
          return { ...prev, foundPage: 1 };
        });
      }
    };

    if (page.foundPage !== 1 || page.lostPage !== 1) {
      setTimeout(() => {
        getDetails(
          "/tv_configuration" + URLS.searchLostPerson,
          setLostPersons,
          8,
          setPresentingDoc
        );

        getDetails(
          "/tv_configuration" + URLS.searchFoundPerson,
          setFoundPersons,
          4
          // setPresentingDoc
        );
      }, 1000);
    } else {
      getDetails(
        "/tv_configuration" + URLS.searchLostPerson,
        setLostPersons,
        8,
        setPresentingDoc
      );

      getDetails(
        "/tv_configuration" + URLS.searchFoundPerson,
        setFoundPersons,
        4
        // setPresentingDoc
      );
    }
  }, [page]);

  useEffect(() => {
    let interval;

    if (lostPersons.length && foundPersons.length) {
      const allDetails = [...lostPersons, ...foundPersons];
      let count = 1;

      interval = setInterval(() => {
        const newDoc = allDetails[count];
        if (count === 1) {
          setPresentingTitle(" खोये हुए व्यक्ति की घोषणा");
        } else if (count === lostPersons.length) {
          setPresentingTitle("पाये हुए व्यक्ति की घोषणा");
        }

        if (newDoc) {
          setPresentingDoc({ ...newDoc });
        }

        count += 1;

        if (count >= allDetails.length) {
          count = 0;

          // Update pages functionally to avoid stale state
          setPage((prevPage) => ({
            lostPage: prevPage.lostPage + 1,
            foundPage: prevPage.foundPage + 1,
          }));
        }
      }, 3000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [lostPersons, foundPersons]);

  return (
    <div className="bg-gray-200 min-h-screen  flex flex-col ">
      <div className=" font-semibold text-2xl p-2 bg-orange-400 text-violet-950">
        <div className="grid grid-cols-2  justify-around  items-center">
          <div>कुंभ 2025</div>
          <div className="flex w-full justify-center font-semibold ">
            खोये/पाये हुए व्यक्ति की घोषणा
          </div>
        </div>
      </div>

      <div className="flex flex-col-reverse xl:flex-row">
        <div className="xl:w-8/12  gap-2 ">
          <div className=" px-4 ">
            <div className="mt-2 text-xl flex justify-center items-center w-full flex-col">
              <div className="text-2xl font-semibold">खोये हुए व्यक्ति</div>
              <hr className="m-1 text-black bg-black w-32" />
            </div>
            <div className="grid mt-3 grid-cols-2 md:grid-cols-4 gap-3">
              {lostPersons.map((el, index) => {
                return (
                  <div key={el.photo + index}>
                    <div className="flex justify-center items-center">
                      <div className=" h-36 sm:h-48 md:h-32 lg:h-56 xl:h-32 2xl:h-52 flex overflow-hidden w-full">
                        <Image
                          style={{ width: "100%" }}
                          width={"100%"}
                          className="w-full"
                          src={URLS.lost_photo_base_url + el.photo}
                        ></Image>
                      </div>
                    </div>
                    <div className="text-center font-semibold text-lg p-1 bg-violet-50 capitalize">
                      {truncateString(
                        el.name_hindi || el.first_name + " " + el.last_name,
                        20
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className=" px-4">
            <div className="mt-2 text-xl flex justify-center items-center w-full flex-col">
              <div className="text-2xl font-semibold">पाये हुए व्यक्ति</div>
              <hr className="m-1 text-black bg-black w-32" />
            </div>
            <div className="grid mt-3 grid-cols-2 md:grid-cols-4 gap-3">
              {foundPersons.map((el, index) => {
                return (
                  <div key={el.photo + index} className="bg-white">
                    <div className="flex justify-center items-center">
                      <div className=" h-36 bg-white sm:h-48 md:h-32 lg:h-56 xl:h-32 2xl:h-52 flex overflow-hidden w-full">
                        <Image
                          style={{ width: "100%" }}
                          width={"100%"}
                          className="w-full"
                          src={URLS.found_photo_base_url + el.photo}
                        ></Image>
                      </div>
                    </div>
                    <div className="text-center p-1 font-semibold text-lg bg-violet-50 capitalize">
                      {truncateString(
                        el.name_hindi || el.first_name + " " + el.last_name,
                        20
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="text-2xl lg:w-4/12 lg:-top-5 m-auto xl:min-h-screen relative  flex flex-col justify-center items-center lg:right-3  ">
          <div className="mt-2  text-2xl flex justify-center items-center w-full flex-col">
            <div className="font-semibold">{presentingTitle}</div>
            <hr className="m-1 text-black bg-black w-52" />
          </div>
          <div className="flex w-full mt-3 justify-center items-center bg-white ">
            <div className="  flex overflow-hidden w-full h-72 sm:h-96 justify-center items-start">
              <Image
                style={{ width: "100%" }}
                width={"100%"}
                className="w-full"
                src={
                  presentingDoc?.complaint_number[0].startsWith("F")
                    ? URLS.found_photo_base_url + presentingDoc?.photo
                    : URLS.lost_photo_base_url + presentingDoc?.photo
                }
              ></Image>
            </div>
          </div>
          <div className=" p-2 w-full bg-violet-50 flex flex-col gap-2   font-semibold text-2xl ">
            <div>
              <span className="font-semibold">नाम: </span>
              <span className="capitalize">
                {" "}
                {presentingDoc.name_hindi ||
                  presentingDoc.first_name + " " + presentingDoc.last_name}
              </span>
            </div>
            <div>
              <span className="font-semibold">आयु: </span>
              {presentingDoc.age} साल
            </div>{" "}
            <div>
              <span className="font-semibold">पोशाक: </span>
              {presentingDoc?.attire_id?.name.split("-")[1] || "_"}
            </div>
            <div>
              <span className="font-semibold">पोशाक का रंग: </span>
              {presentingDoc?.attire_color_id?.name.split("-")[1]}{" "}
            </div>{" "}
            {/* <div>
              <span className="font-semibold">अंतिम बार देखा गया: </span>
              {presentingDoc?.center_id?.sector_id?.name}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TVConfiguration;
