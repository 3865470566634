const URLS = {
  // base_url: "http://localhost:5000/api/v1",
  // user_pho_base_url: "http://localhost:5000/public/img/users/",
  // lost_photo_base_url: "http://localhost:5000/public/img/lost_person/",
  // found_photo_base_url: "http://localhost:5000/public/img/found_person/",
  // face_base_url: "http://localhost:3001/api/v1/uploads/",
  // receipt: "http://localhost:5000/public/receipt/",
  // operatorId: "671f58b99f3126379951b01f",
  // receipt_base_url: "http://localhost:5000/public/receipt/",
  // handover_receipt_base_url: "http://localhost:5000/public/handover_receipt/",
  // handover_doc_base_url: "http://localhost:5000/public/handover/",

  operatorId: "6720bbbe03e01675752483c3",
  base_url: "https://api.kumbhlostandfound.in/api/v1",
  user_pho_base_url: "https://api.kumbhlostandfound.in/public/img/users/",
  lost_photo_base_url:
    "https://api.kumbhlostandfound.in/public/img/lost_person/",
  receipt: "https://api.kumbhlostandfound.in/public/receipt/",
  found_photo_base_url:
    "https://api.kumbhlostandfound.in/public/img/found_person/",
  face_base_url:
    "https://api.kumbhlostandfound.in/api/v1/facedetection/uploads/",
  receipt_base_url: "https://api.kumbhlostandfound.in/public/receipt/",
  handover_receipt_base_url:
    "https://api.kumbhlostandfound.in/public/handover_receipt/",
  handover_doc_base_url: "https://api.kumbhlostandfound.in/public/handover/",

  country: "/countries",
  state: "/states",
  city: "/cities/state",
  user: "/users",
  login: "/users/login",
  role: "/role",
  center: "/center",
  searchLostPerson: "/lost_person",
  getLostByIds: "/lost_person/ids/",
  searchFoundPerson: "/found_person",
  getFoundByIds: "/found_person/ids/",
  rolePermissions: "/role/permissions",
  updatePermissions: "/permissions/updateMany",
  dashboard: "/dashboard/get_numbers",
  get_weeks_statistics: "/dashboard/get_weeks_statistics",
  get_weeks_age_statistics: "/dashboard/get_weeks_age_statistics",
  forgotPassword: "/users/forgotPassword/",
  changePassword: "/users/changePassword/",
  resetPassword: "/users/resetPassword/",
  sector: "/sector",
  attire: "/attire",
  attire_color: "/attire_color",
  handover: "/handover",
  allImages: "/lost_person/all-images",
  save_image: "/save_image",
  announce: "/announcement",
  announceLost: "/announcement/lost_persons",
  announceFound: "/announcement/found_persons",
  announcement: "/announcement/last24hrsReports",
  total_lost: "/dashboard/lost",
  total_found: "/dashboard/found",

  lost_search: "/lost_person?&isFound=false",
  found_search: "/found_person?&isHandover=No",

  total_found_pending: "/dashboard/found/pending",
  total_lost_pending: "/dashboard/lost/pending",

  total_found_reunion: "/dashboard/found/reunion",
  total_lost_reunion: "/dashboard/lost/reunion",

  found_handover_search: "/found_person/handover/",
  lost_handover_search: "/lost_person/handover/",

  recent_cases: "/dashboard/recent_cases",
  calling_status: "/calling_status",
  lostFoundSearch: "/search",
  callingHistory: "/calling_status/history",

  reports: "/reports/?",
  posReport: "/reports/pos-report?",
  aiTracedReport: "/reports/download-ai-traced-report?",
  bhashiniTracedReport: "/reports/download-bhashini-traced-report?",
  lostReports: "/reports/download-lost-report?",
  foundReport: "/reports/download-found-report?",
  handoverReport: "/reports/download-handover-report?",
  callingReport: "/reports/download-calling-report?",
  lastSevenDaysComparision: "/dashboard/last-seven-days-comparison",
  pendingClosed: "/reports/download-pending-resolved-report?",
  itervalReports: "/reports/download-interval-reports?",
  mx_search: "/lost_person/search_mx",
  mxAddIdentity: "/lost_person/search_mx/image",
  mxAddIdentity_found: "/found_person/search_mx/image",
  bhashini: "/bhashini",
  resolutionStatisticsReport: "/reports/resolution-time-statistics-report?",
  uploadExcel: "/lost_person/upload-excel",
};

export default URLS;
