export const DICT = {
  title1: { hi: "महाकुंभ मेला 2025", en: "Maha Kumbh Mela 2025" },
  title2: {
    en: "Digital Lost and Found System",
    hi: "डिजिटल खोया और पाया प्रणाली",
  },
  reports: {
    en: "MIS Reports",
    hi: "एमआईएस रिपोर्ट",
  },
  profile: {
    en: "Profile",
    hi: "प्रोफ़ाइल",
  },
  bodo: {
    en: "Bodo",
    hi: "बोड़ो",
  },
  dashboard: {
    en: "Dashboard",
    hi: "डैशबोर्ड",
  },
  home: {
    hi: "मुख्य पृष्ठ",
    en: "Home",
  },
  tv_configuration: {
    hi: "टीवी कॉन्फ़िगरेशन",
    en: "TV Configuration",
  },
  reg_lost: {
    en: "Register Lost Person",
    hi: "खोया व्यक्ति पंजीकरण",
  },
  reg_found: {
    en: "Register Found Person",
    hi: "मिला व्यक्ति पंजीकरण",
  },
  announcement: {
    hi: "घोषणा",
    en: "Announcement",
  },

  sea_lost: {
    en: "Search Lost Person",
    hi: "खोए हुए व्यक्ति की खोज",
  },
  handover_receipt: {
    hi: "हस्तांतरण रसीद",
    en: "Handover Receipt",
  },
  sea_found: {
    en: "Search Found Person",
    hi: "मिले  हुए व्यक्ति की खोजें",
  },

  emp_reg: {
    en: "User Registration",
    hi: "प्रयोगकर्ता पंजीकरण",
  },
  city_reg: {
    en: "Citizen Registration",
    hi: "नागरिक पंजीकरण",
  },
  help: {
    en: "Help",
    hi: "सहायता",
  },
  login: {
    en: "Login",
    hi: "लॉगिन",
  },
  logout: {
    en: "Logout",
    hi: "लॉगआउट",
  },
  register: {
    en: "Register",
    hi: "पंजीकृत करें",
  },
  search: {
    en: "Search",
    hi: "खोज",
  },
  user_reg: {
    en: "User Registration",
    hi: "उपयोगकर्ता पंजीकरण",
  },
  notice_board: {
    en: "Notice Board",
    hi: "सूचना बोर्ड",
  },
  translator: {
    en: "Translator",
    hi: "अनुवादक",
  },
  bring_fam: {
    en: "Reuniting Loved Ones, One Step at a Time",
    hi: "प्रियजनों को फिर से मिलाना, एक कदम एक बार में",
  },
  recent_load_found: {
    en: "Recent Lost & Found Cases",
    hi: "हाल की खोई और पाई गई घटनाएँ",
  },
  toll_free: {
    en: "24x7 Toll Free - 1920",
    hi: "24x7 टोल फ्री - 1920",
  },
  call_free: {
    en: "Call toll free number - 1231231232",
    hi: "टोल फ्री नंबर पर कॉल करें - 1231231232",
  },
  email: {
    en: "Email",
    hi: "ईमेल",
  },
  web_portal: {
    en: "Web Portal Visit",
    hi: "वेब पोर्टल पर जाएँ",
  },
  download_app: {
    en: "Download App",
    hi: "ऐप डाउनलोड करें",
  },
  statistics: {
    en: "Statistics",
    hi: "आंकड़े",
  },
  total_lost: {
    en: "Total Lost Persons Registered",
    hi: "कुल पंजीकृत खोए हुए व्यक्ति",
  },
  total_found: {
    en: "Total Found Persons Registered",
    hi: "कुल पंजीकृत मिले हुए व्यक्ति",
  },
  total_reunion: {
    en: "Total Reunion Completed",
    hi: "कुल पुनर्मिलन पूर्ण",
  },
  pending_cases: {
    en: "Pending Cases",
    hi: "लंबित मामले",
  },
  support_info: {
    en: "Support and Information",
    hi: "समर्थन और जानकारी",
  },
  city_corner: {
    en: "Citizen's Corner",
    hi: "नागरिक का कोना",
  },
  info_missing: {
    en: (
      <>
        <li className="hover:text-ec">Sector 14 -Near Bridge No. 14</li>
        <li className="hover:text-ec">
          Sector 17 - Near Police Chowki Central{" "}
        </li>
        <li className="hover:text-ec">Sector 17 - Near Police Post Utari</li>
        <li className="hover:text-ec">Sector 18 - Opposite Parmarth Niketan</li>
      </>
    ),
    hi: (
      <>
        <li> सेक्टर 14 -नियर पुल नं 14</li>
        <li>सेक्टर 17 -पुलिस चौकी मध्य के पास</li>
        <li> सेक्टर 17 -पुलिस चौकी उटारी के पास</li>
        <li> सेक्टर 18 -परमार्थ निकेतन के सामने</li>
      </>
    ),
  },
  info_found: {
    en: "Inform About Found Person",
    hi: "मिले हुए व्यक्ति की जानकारी दें",
  },
  useful_links: {
    en: "Useful Links",
    hi: "उपयोगी लिंक",
  },
  photo_missing_found: {
    en: "Photographs of Missing/Found Person",
    hi: "लापता/मिले हुए व्यक्ति की तस्वीरें",
  },
  check_status: {
    en: "Check The Status of Your Complaint of a Missing Person",
    hi: "लापता व्यक्ति की शिकायत की स्थिति जांचें",
  },
  quick_search: {
    en: "Quick Search",
    hi: "त्वरित खोज",
  },
  login_section: {
    en: "Login Section",
    hi: "लॉगिन अनुभाग",
  },
  police: {
    en: "Police",
    hi: "पुलिस",
  },
  mela_official: {
    en: "Mela Official",
    hi: "मेला अधिकारी",
  },
  lost_found_center: {
    en: "Lost & Found Centers",
    hi: "खोया और पाया केंद्र",
  },
  citizens: {
    en: "Citizens",
    hi: "नागरिक",
  },
  todays_statistics: {
    en: "Today's Statistics",
    hi: "आज की सांख्यिकी",
  },
  lost: {
    en: "Lost",
    hi: "खोया हुआ",
  },
  found: {
    en: "Found",
    hi: "मिला हुआ",
  },

  pending: {
    en: "Pending",
    hi: "लंबित",
  },
  reunion: {
    en: "Reunion",
    hi: "पुनर्मिलन",
  },
  info: {
    en: "Information",
    hi: "जानकारी",
  },
  objective: {
    en: "Objective",
    hi: "उद्देश्य",
  },
  local_help: {
    en: "Your Local Help",
    hi: "आपकी स्थानीय सहायता",
  },
  do_donts: {
    en: "Do's & Don'ts",
    hi: "क्या करें और क्या न करें",
  },
  web_links: {
    en: "Useful Links",
    hi: "उपयोगी लिंक",
  },
  kumbh_mela: {
    en: "Kumbhmela",
    hi: "कुंभ मेला",
  },
  up_police: {
    en: "UP Police",
    hi: "उत्तर प्रदेश पुलिस",
  },
  faq: {
    en: "FAQ",
    hi: "अक्सर पूछे जाने वाले प्रश्न",
  },
  community_forum: {
    en: "Community Forum",
    hi: "सामुदायिक मंच",
  },
  technical_support: {
    en: "Technical Support",
    hi: "तकनीकी सहायता",
  },
  citizen_feedback: {
    en: "Citizen Feedback",
    hi: "नागरिक प्रतिक्रिया",
  },
  contact: {
    en: "Contact",
    hi: "संपर्क",
  },
  address: {
    en: "Address",
    hi: "पता",
  },

  address_footer: {
    en: "Kumbh Mela Adhikari Triveni Bhawan, Prayagraj, Uttar Pradesh.",
    hi: "कुंभ मेला अधिकारी त्रिवेणी भवन, प्रयागराज, उत्तर प्रदेश।",
  },
  email_footer: {
    en: "helpdesk@kumbhlostandfound.in",
    hi: "helpdesk@kumbhlostandfound.in",
  },
  phone: { en: "Phone", hi: "फ़ोन" },
  phone_number: {
    en: "+919876543210",
    hi: "+919876543210",
  },
  emmergency: {
    en: "Emmergency",
    hi: "आपातकाल",
  },
  scan_qr: {
    en: "Scan QR",
    hi: "क्यूआर कोड स्कैन करें",
  },
  last_footer: {
    en: "Copyright © 2024 Kash IT Solutions Limited, Pune",
    hi: "कॉपीराइट © 2024 काश आईटी सॉल्यूशंस लिमिटेड, पुणे",
  },
  setting: {
    en: "Setting",
    hi: "सेटिंग",
  },
  name: {
    en: "Name",
    hi: "नाम",
  },
  age: {
    en: "Age",
    hi: "उम्र",
  },
  emmergency_no: {
    en: "Emergency No.",
    hi: "आपातकालीन नंबर",
  },
  ambulance: { hi: "एम्बुलेंस", en: "Ambulance" },
  fire: { hi: "आग", en: "Fire" },
  helpline: {
    en: "Helpline",
    hi: "हेल्पलाइन",
  },

  // ********************************
  role: {
    en: "Role",
    hi: "भूमिका",
  },
  manage_permissions: {
    en: "Manage Permissions",
    hi: "अनुमतियाँ प्रबंधित करें",
  },
  handover: {
    en: "Handover",
    hi: "हस्तांतरण",
  },
  handover_receipt: {
    en: "Handover Receipt",
    hi: "हस्तांतरण रसीद",
  },
  user: {
    en: "User",
    hi: "उपयोगकर्ता",
  },
  add_center: {
    en: "Add Center",
    hi: "केंद्र जोड़ें",
  },
};

// *************Lnding Page**********

const langingPage = {
  select_language: { en: "Select Receipt Language", hi: "रसीद भाषा चुनें" },
  isFound: {
    en: "Is Found",
    hi: "मिला है",
  },
  caseRegisteredAt: {
    en: "Case Registered At",
    hi: "मामला दर्ज हुआ",
  },
  is_reported_through_1920: {
    en: "Is Reported Through 1920",
    hi: "क्या 1920 के माध्यम से रिपोर्ट किया गया है",
  },
  raj: {
    en: "Rajasthani",
    hi: "राजस्थानी",
  },
  profile: {
    en: "Profile",
    hi: "प्रोफ़ाइल",
  },
  vmd: {
    en: "VMD",
    hi: "वीएमडी",
  },
  police_station_post_office: {
    en: "Police Station/Post Office",
    hi: "पुलिस थाना/डाकघर",
  },
  additional_info: {
    en: "Additional Information",
    hi: "अतिरिक्त जानकारी",
  },
  reuniting_loved_ones: {
    en: "Reuniting Loved Ones",
    hi: "प्यारे लोगों को मिलाना",
  },
  maha_kumbh_mela_2025: {
    en: "Maha Kumbh Mela 2025",
    hi: "महाकुंभ मेला 2025",
  },
  lost_and_found_tracking: {
    en: "Digital Lost and Found System",
    hi: "डिजिटल खोया और पाया प्रणाली",
  },
  prayagraj_kumbh_mela: {
    en: "Prayagraj Kumbh Mela",
    hi: "प्रयागराज कुंभ मेला",
  },
  helpline_no: {
    en: "Helpline No.",
    hi: "हेल्पलाइन नंबर",
  },
  kumbh_mela: {
    en: "Kumbh Mela",
    hi: "कुंभ मेला",
  },
  lost_found_tracking_system: {
    en: "Digital Lost and Found System",
    hi: "डिजिटल खोया और पाया प्रणाली",
  },
  stay_connected_kumbh_mela: {
    en: "Stay connected and ensure the safety of your loved ones during the Kumbh Mela. If you've lost someone or something, we're here to assist. Call our dedicated helpline for immediate help.",
    hi: "कुंभ मेला के दौरान अपने प्रियजनों की सुरक्षा सुनिश्चित करें और जुड़े रहें। यदि आपने किसी को या किसी चीज़ को खो दिया है, तो हम यहाँ सहायता के लिए हैं। तत्काल सहायता के लिए हमारी समर्पित हेल्पलाइन पर कॉल करें।",
  },
  todays_lost_persons_enrolled: {
    en: "Today's Lost Report",
    hi: "आज के खोए हुए व्यक्ति पंजीकृत",
  },
  todays_found_persons_enrolled: {
    en: "Today's Found Report",
    hi: "आज के पाए गए व्यक्ति पंजीकृत",
  },
  todays_reunion_completed: {
    en: "Today's Reunion Completed",
    hi: "आज की पुनर्मिलन पूरी हुई",
  },
  todays_pending: {
    en: "Today's Pending",
    hi: "आज के लंबित",
  },
  cases: {
    en: "Cases",
    hi: "मामले",
  },
  percent_no_change: {
    en: "% No Change",
    hi: "% कोई बदलाव नहीं",
  },
  percent_lower_than_yesterday: {
    en: "% Lower Than Yesterday",
    hi: "% कल से कम",
  },
  percent_higher_than_yesterday: {
    en: "% Higher Than Yesterday",
    hi: "% कल से अधिक",
  },
  total_found_enrolled: {
    en: "Total Found Report",
    hi: "कुल पाए गए रिपोर्ट दर्ज",
  },
  total_lost_enrolled: {
    en: "Total Lost Report",
    hi: "कुल खोए हुए रिपोर्ट दर्ज",
  },

  total_Report_enrolled: {
    en: "Total Report Registered",
    hi: "कुल रिपोर्ट पंजीकृत",
  },
  total_reunite_cases: {
    en: "Total Reunite Cases",
    hi: "कुल पुनर्मिलन मामले",
  },
  total_lost_enrolled: {
    en: "Total Lost Enrolled",
    hi: "कुल गुमशुदा पंजीकृत",
  },
  total_found_enrolled: {
    en: "Total Found Enrolled",
    hi: "कुल मिले हुए पंजीकृत",
  },
  total_unresolved_cases: {
    en: "Total Unresolved Cases",
    hi: "कुल अपठित मामले",
  },
  average_time_to_resolve_case: {
    en: "Average Time to Resolve Case",
    hi: "मामला हल करने का औसत समय",
  },
  total_lost: {
    en: "Total Lost",
    hi: "कुल खोए हुए",
  },
  total_found: {
    en: "Total Found",
    hi: "कुल पाए गए",
  },
  reunite: {
    en: "Reunite",
    hi: "पुनर्मिलन",
  },
  unresolved: {
    en: "Unresolved",
    hi: "अनसुलझा",
  },
  recent_load_found: {
    en: "Recent Lost & Found Cases",
    hi: "हाल की खोई और पाई गई घटनाएँ",
  },
  total_lost_persons_enrolled: {
    en: "Total Lost Persons Registered",
    hi: "कुल खोए हुए व्यक्ति पंजीकृत",
  },
  values: {
    en: "Values",
    hi: "मूल्य",
  },
  last_three_days_comparison: {
    en: "Last Seven Days Comparison",
    hi: "पिछले सात दिनों की तुलना",
  },
  case_count: {
    en: "Case Count",
    hi: "मामलों की संख्या",
  },
  sun: {
    en: "Sun",
    hi: "रविवार",
  },
  mon: {
    en: "Mon",
    hi: "सोमवार",
  },
  tue: {
    en: "Tue",
    hi: "मंगलवार",
  },
  wed: {
    en: "Wed",
    hi: "बुधवार",
  },
  thur: {
    en: "Thur",
    hi: "गुरुवार",
  },
  fir: {
    en: "Fri",
    hi: "शुक्रवार",
  },
  sat: {
    en: "Sat",
    hi: "शनिवार",
  },
  lost_cases: {
    en: "Lost Cases",
    hi: "खोए हुए मामले",
  },
  found_cases: {
    en: "Found Cases",
    hi: "पाए गए मामले",
  },
  reunite_cases: {
    en: "Reunite Cases",
    hi: "पुनर्मिलन मामले",
  },
  unresolved_cases: {
    en: "Unresolved Cases",
    hi: "अनसुलझे मामले",
  },
  locations_of_lost_and_found_cases: {
    en: "Locations of Lost and Found Cases",
    hi: "खोए और पाए गए मामलों के स्थान",
  },
  high_density_found: {
    en: "High Density Found",
    hi: "उच्च घनत्व पाए गए",
  },
  high_density_lost: {
    en: "High Density Lost",
    hi: "उच्च घनत्व खोए हुए",
  },
  citizen: {
    en: "Citizen",
    hi: "नागरिक",
  },
  user: {
    en: "User",
    hi: "उपयोगकर्ता",
  },
  name: {
    en: "Name",
    hi: "नाम",
  },
  email: {
    en: "Email",
    hi: "ईमेल",
  },
  phone: {
    en: "Phone",
    hi: "फ़ोन",
  },
  selectRole: {
    en: "Select Role",
    hi: "भूमिका चुनें",
  },
  roleRequired: {
    en: "Please select role!",
    hi: "कृपया भूमिका चुनें!",
  },
  name: {
    en: "Name",
    hi: "नाम",
  },
  email: {
    en: "Email",
    hi: "ईमेल",
  },
  phone: {
    en: "Phone",
    hi: "फ़ोन",
  },
  photo: {
    en: "Photo",
    hi: "फ़ोटो",
  },
  role: {
    en: "Role",
    hi: "भूमिका",
  },
  center: {
    en: "Center",
    hi: "केंद्र",
  },
  address: {
    en: "Address",
    hi: "पता",
  },
  caseResolvedAt: {
    en: "Case Resolved At",
    hi: "मामला हल हुआ",
  },
  action: {
    en: "Action",
    hi: "क्रिया",
  },
  reset: {
    en: "Reset",
    hi: "रीसेट",
  },
  search: {
    en: "Search",
    hi: "खोज",
  },
  total: {
    en: "Total",
    hi: "कुल",
  },
  manage_permissions: {
    en: "Manage Permissions",
    hi: "अनुमतियाँ प्रबंधित करें",
  },
  select_role: {
    en: "Select Role",
    hi: "भूमिका चुनें",
  },
  module: {
    en: "Module",
    hi: "मॉड्यूल",
  },
  update: {
    en: "Update",
    hi: "अपडेट करें",
  },
  handover: {
    en: "Handover",
    hi: "हस्तांतरण",
  },
  ticket_number: {
    en: "Ticket Number",
    hi: "टिकट संख्या",
  },
  first_name: {
    en: "First Name",
    hi: "पहला नाम",
  },
  last_name: {
    en: "Last Name",
    hi: "अंतिम नाम",
  },
  age: {
    en: "Age",
    hi: "आयु",
  },
  center: {
    en: "Center",
    hi: "केंद्र",
  },
  date_of_birth: {
    en: "Date of Birth",
    hi: "जन्म तिथि",
  },
  gender: {
    en: "Gender",
    hi: "लिंग",
  },
  complainant_phone_number: {
    en: "Complainant Phone Number",
    hi: "शिकायतकर्ता का फोन नंबर",
  },
  complainant_name: {
    en: "Complainant Name",
    hi: "शिकायतकर्ता का नाम",
  },
  receiver_information: {
    en: "Receiver Information",
    hi: "प्राप्तकर्ता जानकारी",
  },
  date: {
    en: "Date",
    hi: "तारीख",
  },
  relation: {
    en: "Relation",
    hi: "संबंध",
  },
  phone_number: {
    en: "Phone Number",
    hi: "फोन नंबर",
  },
  enter_ticket_number: {
    en: "Enter Ticket Number",
    hi: "टिकट संख्या दर्ज करें",
  },
  enter_center_number: {
    en: "Enter Center Number",
    hi: "केंद्र संख्या दर्ज करें",
  },
  enter_name: {
    en: "Enter Name",
    hi: "नाम दर्ज करें",
  },
  enter_phone_number: {
    en: "Enter Phone Number",
    hi: "फोन नंबर दर्ज करें",
  },
  enter_complainant_name: {
    en: "Enter Complainant Name",
    hi: "शिकायतकर्ता का नाम दर्ज करें",
  },
  select_gender: {
    en: "Select Gender",
    hi: "लिंग चुनें",
  },
  select_relation: {
    en: "Select Relation",
    hi: "संबंध चुनें",
  },
  select_date: {
    en: "Select Date",
    hi: "तारीख चुनें",
  },
  enter_address: {
    en: "Enter Address",
    hi: "पता दर्ज करें",
  },
  enter_phone_number: {
    en: "Enter Phone Number",
    hi: "फोन नंबर दर्ज करें",
  },
  submit: {
    en: "Submit",
    hi: "जमा करें",
  },
  lost_and_found_search: {
    en: "Lost & Found Search",
    hi: "खोए और पाए गए की खोज",
  },
  male: {
    en: "Male",
    hi: "पुरुष",
  },
  female: {
    en: "Female",
    hi: "महिला",
  },
  lost_persons: {
    en: "Lost Persons",
    hi: "खोए हुए व्यक्ति",
  },
  found_persons: {
    en: "Found Persons",
    hi: "पाए गए व्यक्ति",
  },
  please_enter_info_to_search: {
    en: "Please enter some information to perform the search.",
    hi: "खोज करने के लिए कृपया कुछ जानकारी दर्ज करें।",
  },
  note: {
    en: "Note",
    hi: "नोट",
  },
  please_enter_ticket_number: {
    en: "Please enter the ticket number",
    hi: "कृपया टिकट संख्या दर्ज करें",
  },
  please_select_date: {
    en: "Please select the date",
    hi: "कृपया तारीख चुनें",
  },
  please_enter_relation: {
    en: "Please enter relation",
    hi: "कृपया संबंध दर्ज करें",
  },
  please_enter_receiver_name: {
    en: "Please enter receiver name",
    hi: "कृपया प्राप्तकर्ता का नाम दर्ज करें",
  },
  please_select_gender: {
    en: "Please select gender",
    hi: "कृपया लिंग चुनें",
  },
  please_enter_phone_number: {
    en: "Please enter phone number",
    hi: "कृपया फोन नंबर दर्ज करें",
  },
  please_select_dob: {
    en: "Please select DOB",
    hi: "कृपया जन्म तिथि चुनें",
  },
  please_enter_address: {
    en: "Please enter address",
    hi: "कृपया पता दर्ज करें",
  },
  please_select_gender: {
    en: "Please select gender",
    hi: "कृपया लिंग चुनें",
  },
  please_update_permissions: {
    en: "Please update some permissions!",
    hi: "कृपया कुछ अनुमतियाँ अपडेट करें!",
  },
  update_user_details: {
    en: "Update User Details",
    hi: "उपयोगकर्ता विवरण अपडेट करें",
  },
  all_mandatory_fields: {
    en: "All * marks fields are mandatory",
    hi: "सभी * चिह्नित फ़ील्ड अनिवार्य हैं",
  },
  select_role: {
    en: "Select Role",
    hi: "भूमिका चुनें",
  },
  select_center: {
    en: "Select Center",
    hi: "केंद्र चुनें",
  },
  email_id: {
    en: "Email ID",
    hi: "ईमेल आईडी",
  },
  upload_photo: {
    en: "Upload Photo",
    hi: "फ़ोटो अपलोड करें",
  },
  update: {
    en: "Update",
    hi: "अपडेट करें",
  },
  please_select_center: {
    en: "Please select center!",
    hi: "कृपया केंद्र चुनें!",
  },
  user_registration: {
    en: "User Registration",
    hi: "उपयोगकर्ता पंजीकरण",
  },
  register: {
    en: "Register",
    hi: "पंजीकरण करें",
  },
  create_role: {
    en: "Create Role",
    hi: "भूमिका बनाएं",
  },
  role_name: {
    en: "Role Name",
    hi: "भूमिका का नाम",
  },
  please_enter_role_name: {
    en: "Please enter role name!",
    hi: "कृपया भूमिका का नाम दर्ज करें!",
  },
  center_registration: {
    en: "Center Registration",
    hi: "केंद्र पंजीकरण",
  },
  center_name: {
    en: "Center Name",
    hi: "केंद्र का नाम",
  },
  center_type: {
    en: "Center Type",
    hi: "केंद्र का प्रकार",
  },
  select_sector: {
    en: "Select Sector",
    hi: "क्षेत्र चुनें",
  },

  select_center_type: {
    en: "Select Center Type",
    hi: "केंद्र प्रकार चुनें",
  },
  odia: {
    en: "Odia",
    hi: "ओड़िया",
  },
  manipuri: {
    en: "Manipuri",
    hi: "मणिपुरी",
  },
  assamese: {
    en: "Assamese",
    hi: "असमिया",
  },

  center_address: {
    en: "Center Address",
    hi: "केंद्र का पता",
  },
  please_enter_center_name: {
    en: "Please enter center name!",
    hi: "कृपया केंद्र का नाम दर्ज करें!",
  },
  please_enter_center_type: {
    en: "Please enter Center Type!",
    hi: "कृपया केंद्र प्रकार दर्ज करें!",
  },
  please_select_sector: {
    en: "Please Select Sector!",
    hi: "कृपया क्षेत्र चुनें!",
  },
  please_enter_address_name: {
    en: "Please enter address name!",
    hi: "कृपया पता नाम दर्ज करें!",
  },
  enter_email: {
    en: "Enter email",
    hi: "ईमेल दर्ज करें",
  },
  enter_password: {
    en: "Enter password",
    hi: "पासवर्ड दर्ज करें",
  },
  please_select_role: {
    en: "Please select role!",
    hi: "कृपया भूमिका चुनें!",
  },
  please_enter_name: {
    en: "Please enter name!",
    hi: "कृपया नाम दर्ज करें!",
  },
  please_enter_email: {
    en: "Please enter the email",
    hi: "कृपया ईमेल दर्ज करें",
  },
  please_enter_password: {
    en: "Please enter the password",
    hi: "कृपया पासवर्ड दर्ज करें",
  },
  please_enter_valid_name: {
    en: "Please enter a valid name!",
    hi: "कृपया एक मान्य नाम दर्ज करें!",
  },
  please_enter_valid_email: {
    en: "Please enter a valid email",
    hi: "कृपया एक मान्य ईमेल दर्ज करें",
  },
  please_enter_valid_phone_number: {
    en: "Please enter a valid 10-digit phone number",
    hi: "कृपया एक मान्य 10-अंकीय फोन नंबर दर्ज करें",
  },
  password_minimum_length: {
    en: "Password must be at least 8 characters long",
    hi: "पासवर्ड कम से कम 8 अक्षरों का होना चाहिए",
  },
  invalid_mobile_number: {
    en: "Invalid mobile number. It must be exactly 10 digits long.",
    hi: "अमान्य मोबाइल नंबर। यह ठीक 10 अंकों का होना चाहिए।",
  },
  other: {
    en: "Other",
    hi: "अन्य",
  },
  friend: {
    en: "Friend",
    hi: "मित्र",
  },
  family: {
    en: "Family",
    hi: "परिवार",
  },
  add_center: {
    en: "Add Center",
    hi: "केंद्र जोड़ें",
  },
  read: {
    en: "Read",
    hi: "पढ़ें",
  },
  delete: {
    en: "Delete",
    hi: "हटाएं",
  },
  create: {
    en: "Create",
    hi: "बनाएं",
  },
  receiver_name: {
    en: "Receiver Name",
    hi: "प्राप्तकर्ता का नाम",
  },
  receiver_phone: {
    en: "Receiver Phone",
    hi: "प्राप्तकर्ता का फोन",
  },
  receiver_relation: {
    en: "Receiver Relation",
    hi: "प्राप्तकर्ता का संबंध",
  },
  receiver_address: {
    en: "Receiver Address",
    hi: "प्राप्तकर्ता का पता",
  },
  handover_date: {
    en: "Handover Date",
    hi: "सौंपने की तारीख",
  },
  type: {
    en: "Type",
    hi: "प्रकार",
  },
  sector: {
    en: "Sector",
    hi: "क्षेत्र",
  },
  latitude: {
    en: "Latitude",
    hi: "अक्षांश",
  },
  longitude: {
    en: "Longitude",
    hi: "देशांतर",
  },
  satellite_center: {
    en: "Satellite Center",
    hi: "उपग्रह केंद्र",
  },
  model_center: {
    en: "Model Center",
    hi: "मॉडल केंद्र",
  },
  information_for_ticket_number: {
    en: "Information for Ticket Number: ",
    hi: "टिकट संख्या के लिए जानकारी: ",
  },
  ok: {
    en: "OK",
    hi: "ठीक है",
  },
  field: {
    en: "Field",
    hi: "क्षेत्र",
  },
  value: {
    en: "Value",
    hi: "मूल्य",
  },
  logout: {
    en: "Logout",
    hi: "लॉगआउट",
  },
  lost_and_found_enrollment: {
    en: "Lost & Found Registration",
    hi: "खोया और पाया पंजीकरण",
  },
  enroll_found_person: {
    en: "Register Found Person",
    hi: "मिले हुए व्यक्ति का पंजीकरण करें",
  },
  enroll_lost_person: {
    en: "Register Lost Person",
    hi: "खोए हुए व्यक्ति का पंजीकरण करें",
  },
  no_records_found: {
    en: "No Records Found",
    hi: "कोई रिकॉर्ड नहीं मिला",
  },
  image_based_matches: {
    en: "Image-Based Matches",
    hi: "छवि आधारित मेल",
  },
  lost_person_information: {
    en: "Lost Person Information",
    hi: "खोए हुए व्यक्ति की जानकारी",
  },
  please_enter_first_name: {
    en: "Please enter first name!",
    hi: "कृपया पहला नाम दर्ज करें!",
  },
  please_enter_last_name: {
    en: "Please enter last name!",
    hi: "कृपया अंतिम नाम दर्ज करें!",
  },
  please_select_gender: {
    en: "Please select a gender!",
    hi: "कृपया एक लिंग चुनें!",
  },
  age_year: {
    en: "Age (year)",
    hi: "आयु (वर्ष)",
  },
  height: {
    en: "Height",
    hi: "ऊंचाई",
  },
  select_complexion: {
    en: "Select Complexion",
    hi: "रंग चुनें",
  },
  health_condition: {
    en: "Health Condition",
    hi: "स्वास्थ्य स्थिति",
  },
  any_marking: {
    en: "Any Marking",
    hi: "कोई निशान",
  },
  please_input_age: {
    en: "Please input the age!",
    hi: "कृपया आयु दर्ज करें!",
  },
  select_attire: {
    en: "Select Attire",
    hi: "पहनावा चुनें",
  },
  select_attire_color: {
    en: "Select Attire Color",
    hi: "पहनावे का रंग चुनें",
  },
  last_seen_date: {
    en: "Last Seen Date",
    hi: "अंतिम बार देखी गई तारीख",
  },
  language_spoken: {
    en: "Language Spoken",
    hi: "बोली जाने वाली भाषा",
  },
  select_country: {
    en: "Select Country",
    hi: "देश चुनें",
  },
  state: {
    en: "State",
    hi: "राज्य",
  },
  city: {
    en: "City",
    hi: "शहर",
  },
  taluka: {
    en: "Tehsil",
    hi: "तहसील",
  },
  village: {
    en: "Village",
    hi: "गांव",
  },
  aadhar_number: {
    en: "Aadhar Number",
    hi: "आधार संख्या",
  },
  supporting_doc_1: {
    en: "Supporting Doc 1",
    hi: "समर्थन दस्तावेज़ 1",
  },
  complainant_information: {
    en: "Complainant Information",
    hi: "शिकायतकर्ता की जानकारी",
  },
  mobile_no: {
    en: "Mobile No",
    hi: "मोबाइल नंबर",
  },
  please_select_relation: {
    en: "Please select a relation!",
    hi: "कृपया एक संबंध चुनें!",
  },
  please_input_mobile_number: {
    en: "Please input mobile number!",
    hi: "कृपया मोबाइल नंबर दर्ज करें!",
  },
  enter_last_name: {
    en: "Enter Last Name",
    hi: "अंतिम नाम दर्ज करें",
  },
  enter_first_name: {
    en: "Enter First Name",
    hi: "पहला नाम दर्ज करें",
  },
  select_gender: {
    en: "Select Gender",
    hi: "लिंग चुनें",
  },
  select_date: {
    en: "Select Date",
    hi: "तारीख चुनें",
  },
  enter_age: {
    en: "Enter Age",
    hi: "आयु दर्ज करें",
  },
  medium: {
    en: "Medium",
    hi: "मध्यम",
  },
  tall: {
    en: "Tall",
    hi: "लंबा",
  },
  short: {
    en: "Short",
    hi: "छोटा",
  },
  please_enter_a_valid_first_name: {
    en: "Please enter a valid first name!",
    hi: "कृपया एक वैध पहला नाम दर्ज करें!",
  },
  please_enter_a_valid_last_name: {
    en: "Please enter a valid last name!",
    hi: "कृपया एक वैध अंतिम नाम दर्ज करें!",
  },
  please_select_a_gender: {
    en: "Please select a gender!",
    hi: "कृपया एक लिंग चुनें!",
  },
  please_select_a_valid_date: {
    en: "Please select a valid date!",
    hi: "कृपया एक वैध तारीख चुनें!",
  },
  select_height: {
    en: "Select Height",
    hi: "ऊंचाई चुनें",
  },
  dark_saanwla: {
    en: "Dark/Saanwla",
    hi: "गहरा/सांवला",
  },
  fair: {
    en: "Fair",
    hi: "गोरा",
  },
  dark_saanwla: {
    en: "Dark/Saanwla",
    hi: "गहरा/सांवला",
  },
  please_input_health_condition: {
    en: "Please input health condition!",
    hi: "कृपया स्वास्थ्य स्थिति दर्ज करें!",
  },
  enter_health_condition: {
    en: "Enter Health Condition",
    hi: "स्वास्थ्य स्थिति दर्ज करें",
  },
  enter_any_marking: {
    en: "Enter any marking",
    hi: "कोई भी निशान दर्ज करें",
  },
  wheatish_genhua: {
    en: "Wheatish/Genhua",
    hi: "गेहूंआ/जेनहुआ",
  },
  attire_is_required: {
    en: "Attire is required!",
    hi: "वस्त्र आवश्यक है!",
  },
  attire_color_is_required: {
    en: "Attire Color is required!",
    hi: "वस्त्र का रंग आवश्यक है!",
  },
  please_enter_a_valid_age: {
    en: "Please enter a valid age!",
    hi: "कृपया एक वैध आयु दर्ज करें!",
  },
  enter_taluka: {
    en: "Enter Tehsil",
    hi: "तहसील दर्ज करें",
  },
  enter_village: {
    en: "Enter Village",
    hi: "गांव दर्ज करें",
  },
  please_input_mobile_number: {
    en: "Please input mobile number!",
    hi: "कृपया मोबाइल नंबर दर्ज करें!",
  },
  complainant_mob_number: {
    en: "Complainant Mobile Number",
    hi: "शिकायतकर्ता मोबाइल नंबर",
  },
  mandarin_chinese: {
    en: "Mandarin Chinese",
    hi: "मंडारिन चीनी",
  },
  luxand: {
    en: "Luxand",
    hi: "लक्सैंड",
  },
  open_cv: {
    en: "Open CV",
    hi: "ओपन सीवी",
  },
  accuracy: {
    en: "Accuracy",
    hi: "सटीकता",
  },
  name_based_matches: {
    en: "Name-Based Matches",
    hi: "नाम-आधारित मेल",
  },
  capture_image: {
    en: "Capture Image",
    hi: "छवि कैप्चर करें",
  },
  upload_image: {
    en: "Upload Image",
    hi: "छवि अपलोड करें",
  },
  or: {
    en: "OR",
    hi: "अथवा",
  },
  found_person_information: {
    en: "Found Person Information",
    hi: "मिले हुए व्यक्ति की जानकारी",
  },
  language_spoken: {
    en: "Language Spoken",
    hi: "बोली जाने वाली भाषा",
  },
  lost_place: {
    en: "Lost Place",
    hi: "खोने का स्थान",
  },
  is_found: {
    en: "Is Found",
    hi: "मिला है",
  },
  found_remark: {
    en: "Found Remark",
    hi: "मिले हुए का विवरण",
  },
  relative_information: {
    en: "Relative Information",
    hi: "रिश्तेदार की जानकारी",
  },
  no: {
    en: "No",
    hi: "नहीं",
  },
  yes: {
    en: "Yes",
    hi: "हाँ",
  },
  relative_1: {
    en: "Relative 1",
    hi: "रिश्तेदार 1",
  },
  relative_2: {
    en: "Relative 2",
    hi: "रिश्तेदार 2",
  },
  relative_2_phone: {
    en: "Relative 2 Phone",
    hi: "रिश्तेदार 2 का फोन",
  },
  relative_2_name: {
    en: "Relative 2 Name",
    hi: "रिश्तेदार 2 का नाम",
  },
  relative_1_phone: {
    en: "Relative 1 Phone",
    hi: "रिश्तेदार 1 का फोन",
  },
  relative_1_name: {
    en: "Relative 1 Name",
    hi: "रिश्तेदार 1 का नाम",
  },
  select_relative_2: {
    en: "Select a Relative 2",
    hi: "रिश्तेदार 2 का चयन करें",
  },
  select_relative_1: {
    en: "Select a Relative 1",
    hi: "रिश्तेदार 1 का चयन करें",
  },
  retake: {
    en: "Retake",
    hi: "फिर से लें",
  },
  select_state: {
    en: "Select state",
    hi: "राज्य चुनें",
  },
  select_city: {
    en: "Select city",
    hi: "शहर चुनें",
  },
  ai: {
    hi: "कृत्रिम बुद्धिमत्ता (AI)",
    en: "Artificial Intelligence",
  },
  announcementB: {
    en: "Announcement/Bhashini",
    hi: "घोषणा/भाषिणी",
  },
  announcement: {
    en: "Announcement",
    hi: "घोषणा",
  },
  announce: {
    en: "Announce",
    hi: "घोषित करें",
  },
  play: {
    en: "Play",
    hi: "चलाएं",
  },
  pause: {
    en: "Pause",
    hi: "रोकें",
  },
  total_found_persons_enrolled: {
    en: "Total Found Persons Registered",
    hi: "कुल पाए गए व्यक्तियों का पंजीकरण",
  },
  total_reunited_cases: {
    en: "Total Reunited Cases",
    hi: "कुल पुनर्मिलन मामले",
  },
  todays_statistics: {
    en: "Today's Statistics",
    hi: "आज के आंकड़े",
  },
  total_reunion: {
    en: "Total Reunion",
    hi: "कुल पुनर्मिलन",
  },
  total_pending: {
    en: "Total Pending",
    hi: "कुल लंबित",
  },
  total_statistics: {
    en: "Total Statistics",
    hi: "कुल आंकड़े",
  },
  lost_reunion: {
    en: "Lost Reunion",
    hi: "खोए पुनर्मिलन",
  },
  found_reunion: {
    en: "Found Reunion",
    hi: "मिला हुआ पुनर्मिलन",
  },
  found_pending_cases: {
    en: "Found Pending Cases",
    hi: "मिले हुए लंबित मामले",
  },
  lost_pending_cases: {
    en: "Lost Pending Cases",
    hi: "खोए हुए लंबित मामले",
  },
  add_to_queue: {
    en: "Add to Queue",
    hi: "क्यू में जोड़ें",
  },
  hindi: {
    en: "Hindi",
    hi: "हिंदी",
  },
  english: {
    en: "English",
    hi: "अंग्रेज़ी",
  },
  bengali: {
    en: "Bengali",
    hi: "बंगाली",
  },
  gujarati: {
    en: "Gujarati",
    hi: "गुजराती",
  },
  kannada: {
    en: "Kannada",
    hi: "कन्नड़",
  },
  malayalam: {
    en: "Malayalam",
    hi: "मलयालम",
  },
  bodo: {
    en: "Bodo",
    hi: "बोड़ो",
  },
  marathi: {
    en: "Marathi",
    hi: "मराठी",
  },
  urdu: {
    en: "Urdu",
    hi: "उर्दू",
  },
  punjabi: {
    en: "Punjabi",
    hi: "पंजाबी",
  },
  tamil: {
    en: "Tamil",
    hi: "तमिल",
  },
  telugu: {
    en: "Telugu",
    hi: "तेलुगु",
  },
  calling_status: {
    en: "Calling Status",
    hi: "कॉलिंग स्थिति",
  },
  calling_history: {
    en: "Calling History",
    hi: "कॉलिंग इतिहास",
  },
  complainant_relation: {
    en: "Complainant Relation",
    hi: "शिकायतकर्ता का संबंध",
  },
  status: {
    en: "Status",
    hi: "स्थिति",
  },
  complaint_receipt: {
    en: "Complaint Receipt",
    hi: "शिकायत रसीद",
  },
  remark: {
    en: "Remark",
    hi: "टिप्पणी",
  },
  handover_type: {
    en: "Handover Type",
    hi: "हस्तांतरण प्रकार",
  },
  handover_to: {
    en: "Handover To",
    hi: "हस्तांतरण के लिए",
  },
  receipt: {
    en: "Receipt",
    hi: "रसीद",
  },
  police_station_name: {
    en: "Police Station Name",
    hi: "पुलिस स्टेशन का नाम",
  },
  other_details: {
    en: "Other Details",
    hi: "अन्य विवरण",
  },
  receiver_gender: {
    en: "Receiver Gender",
    hi: "प्राप्तकर्ता लिंग",
  },
  telephonic_trace_or_self: {
    en: "Telephonic Trace",
    hi: "टेलीफोनिक ट्रेस",
  },
  female_handover: {
    en: "Female Handover",
    hi: "महिला हैंडओवर",
  },
  male_handover: {
    en: "Male Handover",
    hi: "पुरुष हैंडओवर",
  },
  child_handover: {
    en: "Child Handover",
    hi: "बालक हैंडओवर",
  },
  police_handover: {
    en: "Police Handover",
    hi: "पुलिस हैंडओवर",
  },
  handover_forms: {
    en: "Handover Form's",
    hi: "हैंडओवर फॉर्म्स",
  },
  handover_list: {
    en: "Handover List",
    hi: "हैंडओवर सूची",
  },
  point_of_source: {
    en: "Point of Source",
    hi: "स्रोत का बिंदु",
  },
  ngo: {
    en: "NGO",
    hi: "एनजीओ",
  },
  display: {
    en: "Display",
    hi: "प्रदर्शन",
  },
  website: {
    en: "Website",
    hi: "वेबसाइट",
  },
  social: {
    en: "Social",
    hi: "सामाजिक",
  },
  police: {
    en: "Police",
    hi: "पुलिस",
  },
  mixed: {
    en: "Mixed",
    hi: "मिश्रित",
  },
  foundBy: {
    en: "Found By",
    hi: "के द्वारा पाया गया",
  },
  point_of_source_required: {
    en: "Point of Source is required!",
    hi: "स्रोत का बिंदु आवश्यक है!",
  },
  user_name: {
    en: "User Name",
    hi: "उपयोगकर्ता नाम",
  },
  password: {
    en: "Password",
    hi: "पासवर्ड",
  },
  please_enter_your_password: {
    en: "Please enter your password!",
    hi: "कृपया अपना पासवर्ड दर्ज करें!",
  },
  please_enter_mobile_number: {
    en: "Please enter mobile number!",
    hi: "कृपया मोबाइल नंबर दर्ज करें!",
  },
  enter_the_captcha: {
    en: "Enter the CAPTCHA",
    hi: "कृपया कैप्चा दर्ज करें",
  },
  spoken_language: {
    en: "Spoken Language",
    hi: "बोली जाने वाली भाषा",
  },
  police_station_name: {
    en: "Police Station Name",
    hi: "पुलिस स्टेशन का नाम",
  },
  ngo_name: {
    en: "NGO Name",
    hi: "एनजीओ का नाम",
  },
  ngo_handover: {
    en: "NGO Handover",
    hi: "एनजीओ हस्तांतरण",
  },
  police_name: {
    en: "Police Name",
    hi: "पुलिस का नाम",
  },
  please_enter_police_station_name: {
    en: "Please enter police station name",
    hi: "कृपया पुलिस स्टेशन का नाम दर्ज करें",
  },
  supporting_document: {
    en: "Supporting document",
    hi: "सहायक दस्तावेज़",
  },
  including_police_witness_details: {
    en: "Including details of police witnesses",
    hi: "पुलिस गवाहों के विवरण सहित",
  },
  name_concern_person: {
    en: "Name: (Concern person)",
    hi: "नाम: (संबंधित व्यक्ति)",
  },
  bhauguna: {
    en: "Bhauguna",
    hi: "भगोना",
  },
  along_with_police_witness_details: {
    en: "Along with Police witness details",
    hi: "पुलिस गवाह विवरण के साथ",
  },
};

export { langingPage };
