import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import calenderIcon from "../assets/Dashboard/calendarIcon.png";
import { Link } from "react-router-dom";
import { langingPage } from "../utils/dictionary";
import { LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import LanguageDropdown from "./LanguageDropdown";

const NavHead = ({ lang, setLang }) => {
  const myDate = new Date();
  const dict = langingPage;

  const options = {
    // weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const navigate = useNavigate();

  const [logName, setLogName] = useState(false);
  const loggedIn = localStorage.getItem("sessionToken");
  const formattedDate = myDate.toLocaleDateString("en-GB", options);

  useEffect(() => {
    if (loggedIn) {
      setLogName(true);
    }
  }, []);

  const handleNavigation = (link) => {
    if (link.includes("welcome")) {
      let id = setInterval(() => {
        if (!localStorage.getItem("sessionToken")) {
          clearInterval(id);
          setLogName(false);
          navigate(link);
        }
      }, 100);
    } else {
      navigate(link);
    }
  };

  return (
    <div className="relative top-0 bg-[#ffa940]">
      <div
        className="font-merriweather flex lg:grid grid-cols-1 md:grid-cols-3 justify-between mx-3 my-1 m-auto mb-1 "
        style={{ height: "73px" }}
      >
        <div className="gap-1 justify-start col-span-1 hidden md:flex items-center">
          <div
            style={{ width: "120px" }}
            className="hidden text-sm bg-white  h-8  p-1 px-2 md:flex gap-1 justify-center items-center rounded"
          >
            <img src={calenderIcon} alt="" />
            <span className="text-xs font-nutino flex justify-center items-center ">
              {formattedDate}
            </span>
          </div>
        </div>

        <Link
          to="/"
          className="no-underline flex flex-col w-full justify-center md:items-center"
        >
          <div className="flex flex-col w-fit">
            <div className="flex flex-col text-start font-semibold sm:text-xl w-full justify-center md:items-center text-green-800 ">
              {dict.maha_kumbh_mela_2025[lang]}
            </div>
            <hr className="mt-1 mb-1 text-black" />
            <div className="flex flex-col text-start font-semibold sm:text-xl w-full justify-center md:items-center text-black ">
              {dict.lost_and_found_tracking[lang]}
            </div>
          </div>
        </Link>

        <div className="flex justify-end items-center gap-2 text-sm">
          <Link to="#" className="no-underline flex flex-col">
            <div className="flex flex-col text-start font-semibold sm:text-xl w-full justify-center items-center  text-green-800 ">
              <LanguageDropdown
                lang={lang}
                setLang={setLang}
              ></LanguageDropdown>
            </div>
          </Link>

          <div>
            {!logName ? (
              <div onClick={() => handleNavigation("/login")}>
                <div className="flex items-center text-black cursor-pointer  w-full justify-start  rounded p-1">
                  <LoginOutlined className="text-xl text-white font-semibold"></LoginOutlined>
                </div>
              </div>
            ) : (
              <div>
                <div
                  onClick={() => {
                    localStorage.clear();
                    handleNavigation("/welcome-to-lost-and-found-2025");
                  }}
                >
                  <div className="flex items-center text-black cursor-pointer  w-full justify-start  rounded p-1">
                    <LogoutOutlined className="text-xl text-white font-semibold"></LogoutOutlined>{" "}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavHead;
