import React, { useRef, useState } from "react";
import CommonTable from "../commonComponents/CommonTable";
import CommonSearchForm from "../commonComponents/CommonSearchForm";
import CommonDivider from "../commonComponents/CommonDivider";
import { Button, Form } from "antd";
import URLS from "../Fetch/URLS";
import { langingPage } from "../utils/dictionary";
import { useOutletContext } from "react-router";
import {
  downloadLostReport,
  formatDateFunction,
  handlePrint,
} from "../utils/commonFunctions";
import { DownloadOutlined, PrinterOutlined } from "@ant-design/icons";

const Handover = ({ permissions }) => {
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  const [searchQuery, setSearchQuery] = useState();

  const [form] = Form.useForm();

  const columns = [
    {
      title: dict.ticket_number[lang],
      dataIndex: "complaint_number",
      key: "ticket_number",
      width: 130,
    },

    {
      title: dict.center[lang],
      dataIndex: "center_id",
      key: "Center",
      width: 140,
      render: (val) => val?.name,
    },
    {
      title: dict.handover_type[lang],
      dataIndex: "type",
      key: "handover_type",
      width: 230,
      render: (val) => val || "-",
    },
    {
      title: dict.point_of_source[lang],
      dataIndex: "receiver_point_of_source",
      key: "receiver_point_of_source",
      width: 200,
    },
    {
      title: dict.handover_to[lang],
      dataIndex: "receiver_handover_to",
      key: "receiver_handover_to",
      width: 200,
      render: (val) => val || "-",
    },
    {
      title: dict.first_name[lang],
      dataIndex: "first_name",
      key: "first_name",
      width: 200,
    },
    {
      title: dict.last_name[lang],
      dataIndex: "last_name",
      key: "last_name",
      width: 200,
    },
    {
      title: dict.receiver_name[lang],
      dataIndex: "receiver_name",
      key: "receiver_name",
      width: 200,
      render: (val) => val || "-",
    },
    {
      title: dict.receiver_relation[lang],
      dataIndex: "receiver_relation",
      key: "receiver_relation",
      width: 200,
      render: (val) => val || "-",
    },
    {
      title: dict.receiver_gender[lang],
      dataIndex: "receiver_gender",
      key: "receiver_gender",
      width: 200,
      render: (val) => val || "-",
    },
    {
      title: dict.receiver_phone[lang],
      dataIndex: "receiver_mobile_no",
      key: "receiver_phone",
      width: 200,
      render: (val) => val || "-",
    },
    {
      title: dict.police_station_name[lang],
      dataIndex: "receiver_police_station_name",
      key: "receiver_gender",
      width: 200,
      render: (val) => val || "-",
    },

    {
      title: dict.receiver_address[lang],
      dataIndex: "receiver_address",
      key: "receiver_address",
      width: 300,
    },
    {
      title: dict.other_details[lang],
      dataIndex: "receiver_other_details",
      key: "receiver_other_details",
      width: 200,
      render: (val) => val || "-",
    },
    // {
    //   title: "Supporting Doc",
    //   dataIndex: "receiver_supporting_doc",
    //   key: "receiver_supporting_doc",
    //   width: 150,
    //   render: (val) =>
    //     val ? (
    //       <div
    //         className="flex w-full justify-center"
    //         onClick={(val) => handlePrint(URLS.handover_doc_base_url + val)}
    //       >
    //         <PrinterOutlined className="text-2xl" />
    //       </div>
    //     ) : (
    //       "-"
    //     ),
    // },
    {
      title: dict.receipt[lang],
      dataIndex: "handover_receipt",
      key: "handover_receipt",
      width: 100,
      fixed: "right",
      render: (val) =>
        val ? (
          <div
            className="flex w-full justify-center"
            onClick={() => handlePrint(URLS.handover_receipt_base_url + val)}
          >
            <PrinterOutlined className="text-2xl" />
          </div>
        ) : (
          "-"
        ),
    },

    {
      title: dict.remark[lang],
      dataIndex: "receiver_remark",
      key: "receive_remark",
      width: 150,
      render: (val) => val || "-",
    },
    {
      title: dict.handover_date[lang],
      dataIndex: "receiver_handover_date",
      key: "receiver_handover_date",
      width: 150,
      fixed: "right",
      render: (date) =>
        (date && formatDateFunction(date?.split("T")[0])) || "-",
    },

    // {
    //   title: "Print",
    //   dataIndex: "receipt",
    //   key: "print",
    //   width: 100,
    //   fixed: "right",
    //   render: (receipt) => {
    //     return (
    //       <>
    //         <button onClick={() => handlePrint(receipt)}>Print PDF</button>
    //       </>
    //     );
    //   },
    // },
  ];

  const [loading, setLoading] = useState(false);

  return (
    <div>
      <CommonDivider label={dict.handover[lang]} />

      <CommonSearchForm
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        form={form}
        fields={[{ name: "complaint_number", label: dict.ticket_number[lang] }]}
        dropFields={[
          {
            name: "type",
            placeholder: dict.handover_type[lang],
            options: [
              { label: dict.handover[lang], value: "Handover" },
              {
                label: dict.police_handover[lang],
                value: "Police Handover",
              },
              {
                label: dict.ngo_handover[lang],
                value: "NGO Handover",
              },
              // {
              //   label: dict.male_handover[lang],
              //   value: "Male Handover",
              // },
              // {
              //   label: dict.female_handover[lang],
              //   value: "Female Handover",
              // },
              // {
              //   label: dict.telephonic_trace_or_self[lang],
              //   value: "Telephonic Trace or Self - टेलीफोनिक ट्रेस या स्वयं",
              // },
            ],
          },
          {
            name: "receiver_point_of_source",
            placeholder: dict.point_of_source[lang],
            options: [
              {
                label: dict.ai[lang],
                value: "Artificial Intelligence",
              },
              {
                label: "Announcement/Bhashini",
                value: "Announcement/Bhashini",
              },
              {
                label: dict.display[lang],
                value: "TV",
              },
              {
                label: dict.social[lang],
                value: "Social Media",
              },
              {
                label: dict.social[lang],
                value: "Telephonic Trace",
              },
              {
                label: dict.other[lang],
                value: "Other",
              },
              // {
              //   label: dict.telephonic_trace_or_self[lang],
              //   value: "Telephonic Trace or Self - टेलीफोनिक ट्रेस या स्वयं",
              // },
            ],
          },
        ]}
      />

      {JSON.parse(localStorage.getItem("mis")) && (
        <div className="mb-2 w-full flex justify-end gap-3">
          <Button
            className="bg-violet-200 font-semibold"
            onClick={() => {
              let url = URLS.handoverReport;
              if (form.getFieldValue("type")) {
                url = url + "type=" + form.getFieldValue("type");
              }

              if (form.getFieldValue("receiver_point_of_source")) {
                url =
                  url +
                  "receiver_point_of_source=" +
                  form.getFieldValue("receiver_point_of_source");
              }

              setLoading(true);
              downloadLostReport(setLoading, "Handover Report", url);
            }}
            loading={loading}
          >
            <DownloadOutlined></DownloadOutlined>
            Handover Report
          </Button>
        </div>
      )}

      <CommonTable
        uri="handover"
        reactBaseUrl="handover"
        columns={columns}
        spreadOptions={["lostPerson", "foundPerson"]}
        extraParams={searchQuery}
        form={form}
        scroll={{ x: 1400, y: 400 }}
      />
    </div>
  );
};

export default Handover;
