import { Form, DatePicker } from "antd";
import React from "react";
import "antd/dist/reset.css";
const { RangePicker } = DatePicker;

const AntdFormItem = ({ name }) => {
  return (
    <Form.Item
      noStyle
      name={name}
      rules={[{ required: true, message: "Please select a date range!" }]}
    >
      <RangePicker />
    </Form.Item>
  );
};

export default AntdFormItem;
