import React, { useEffect, useState } from "react";
import { Modal, Button, Table, Image } from "antd";
import { Link, useOutletContext } from "react-router-dom";
import URLS from "../../Fetch/URLS";
import { langingPage } from "../../utils/dictionary";
import { formatDateFunction, handlePrint } from "../../utils/commonFunctions";

const DataTableModal = ({
  isModalVisible,
  setIsModalVisible,
  onlyLimited = true,
}) => {
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const columns = [
    { title: dict.field[lang], dataIndex: "field", key: "field" },
    { title: dict.value[lang], dataIndex: "value", key: "value" },
  ];

  const [dataSource, setDataSource] = useState([]);

  function capitalizeFirstLetter(str) {
    if (!str) return str; // Return if the string is empty or null
    str = str.charAt(0).toUpperCase() + str.slice(1);

    return str.split("_").join(" ");
  }

  useEffect(() => {
    if (isModalVisible) {
      let token = localStorage.getItem("sessionToken") && onlyLimited;
      delete isModalVisible["action"];
      delete isModalVisible["key"];

      delete isModalVisible["createdAt"];
      delete isModalVisible["updatedAt"];
      delete isModalVisible["Photo"];

      let data = {
        ...isModalVisible,
        center: isModalVisible?.center_id?.name,
        sector: isModalVisible?.center_id?.sector_id?.name,
        first_name: isModalVisible?.first_name,
        last_name: isModalVisible?.last_name,
        age: isModalVisible?.age,
        gender: isModalVisible?.gender,
        attire: isModalVisible?.attire_id?.name,
        attire_color: isModalVisible?.attire_color_id?.name,
        dob: isModalVisible?.dob && formatDateFunction(isModalVisible?.dob),
      };

      if (token) {
        data = {
          ...data,
          receipt: (
            <div
              className="text-blue-700"
              onClick={() => {
                handlePrint(URLS.receipt_base_url + isModalVisible?.receipt);
              }}
            >
              View Receipt
            </div>
          ),
          case_resolved_at:
            isModalVisible?.case_resolved_at &&
            formatDateFunction(isModalVisible?.case_resolved_at),
          sector: isModalVisible?.center_id?.sector_id?.name,
          health_condition: isModalVisible?.disability_desc,
          language_spoken: isModalVisible?.language_spoken,
          aadhar_no: isModalVisible?.aadhar_no,
          center: isModalVisible?.center_id?.name,
          marking: isModalVisible?.any_mark,
          lost_place: isModalVisible?.lost_place,
          attire: isModalVisible?.attire_id?.name,
          attire_color: isModalVisible?.attire_color_id?.name,
          country: isModalVisible?.country?.name,
          state: isModalVisible?.state?.name,
          city: isModalVisible?.city?.name,
          tehsil: isModalVisible?.taluka,
          police_station: isModalVisible?.police_station,
          address: isModalVisible?.address,
          Village: isModalVisible?.village,
        };

        if (isModalVisible?.complaint_number[0] === "L") {
          data = {
            ...data,
            supporting_doc_1: isModalVisible?.supporting_doc_1 ? (
              <Link
                target="_blank"
                to={URLS.lost_photo_base_url + isModalVisible?.supporting_doc_1}
              >
                Supporting Document
              </Link>
            ) : (
              "-"
            ),
            relation: isModalVisible?.relation,
            complainant_name: isModalVisible?.complainant_name,
            complainant_mob_number: isModalVisible?.complainant_mob_number,
            isFound: isModalVisible?.isFound ? "Yes" : "No",
          };
        } else {
          data = {
            ...data,
            supporting_doc_1: isModalVisible?.supporting_doc_1 ? (
              <Link
                target="_blank"
                to={
                  URLS.found_photo_base_url + isModalVisible?.supporting_doc_1
                }
              >
                Supporting Document
              </Link>
            ) : (
              "-"
            ),

            relative_1: isModalVisible?.relative_1,
            relative_1_name: isModalVisible?.relative_1_name,
            relative_1_phone: isModalVisible?.relative_1_phone,
            relative_2: isModalVisible?.relative_2,
            relative_2_name: isModalVisible?.relative_2_name,
            relative_2_phone: isModalVisible?.relative_2_phone,
            is_Handover: isModalVisible?.isHandover,
          };
        }
      }

      const dataSource = [];

      for (const key in data) {
        if (key.includes("_id")) {
          delete isModalVisible[key];
          continue;
        }

        dataSource.push({
          field: capitalizeFirstLetter(key),
          value: data[key] || "-",
        });
      }

      setDataSource(() => dataSource);
    }
  }, [isModalVisible]);

  return (
    <Modal
      title={
        <div className="w-full text-center text-xl">
          {dict.information_for_ticket_number[lang]}{" "}
          {isModalVisible?.complaint_number}
        </div>
      }
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleOk}
      footer={false}
      width={600}
    >
      <Table dataSource={dataSource} columns={columns} bordered />
    </Modal>
  );
};

export default DataTableModal;
