import React from "react";
import { langingPage } from "../../utils/dictionary";
import { DatePicker, Form, Radio, Select } from "antd";

import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
dayjs.extend(utc);
const { Option } = Select;

const AdditionalInfo = ({ lang, caseResolved, form }) => {
  let dict = langingPage;

  return (
    <div className="border p-2 pt-3">
      <h2 className="text-base font-bold " style={{ marginTop: "-15px" }}>
        {dict.additional_info[lang]}
      </h2>
      <div className="mt-3 flex gap-3 flex-wrap">
        <Form.Item
          name="receipt_lang"
          label={
            <div className="font-semibold">{dict.select_language[lang]}</div>
          }
        >
          <Radio.Group>
            <Radio value="en">English</Radio>
            <Radio value="hi">हिंदी</Radio>
          </Radio.Group>
        </Form.Item>

        {caseResolved && (
          <>
            <Form.Item
              name="created_at"
              label={
                <div className="font-semibold">
                  {dict.caseRegisteredAt[lang]}
                </div>
              }
            >
              <DatePicker disabled={true} size="small" showTime />
            </Form.Item>
            <Form.Item
              name="case_resolved_at"
              label={
                <div className="font-semibold">{dict.caseResolvedAt[lang]}</div>
              }
              rules={[
                {
                  validator: (_, value) => {
                    if (
                      !value ||
                      value >
                        dayjs(
                          form.getFieldsValue("created_at").created_at
                        ).utc()
                    ) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        `Please select a date after ${dayjs(
                          form.getFieldsValue("created_at").created_at
                        ).utc()}.`
                      )
                    );
                  },
                },
              ]}
            >
              <DatePicker size="small" showTime />
            </Form.Item>

            <Form.Item
              name="is_reported_through_1920"
              label={
                <div className="font-semibold">
                  {dict.is_reported_through_1920[lang]}
                </div>
              }
            >
              <Select
                size="small"
                placeholder={dict.is_reported_through_1920[lang]}
              >
                <Option value={true}>{dict.yes[lang]}</Option>
                <Option value={false}>{dict.no[lang]}</Option>
              </Select>
            </Form.Item>
          </>
        )}
      </div>
    </div>
  );
};

export default AdditionalInfo;
