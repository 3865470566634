import React, { useEffect } from "react";
import CommonDivider from "../../commonComponents/CommonDivider";
import CommonSearchForm from "../../commonComponents/CommonSearchForm";
import { useState } from "react";
import { Button, Form, Image, message } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router";
import { FaUserEdit } from "react-icons/fa";
import { setUpdateSearchLostEl } from "./searchLostSlice";
import URLS from "../../Fetch/URLS";
import CommonSearch from "../../commonComponents/CommonSearchTable";
import { generateSearchQuery } from "../../utils/getSearchQuery";
import DataTableModal from "./LostFoundModel";
import { langingPage } from "../../utils/dictionary";
import { downloadLostReport } from "../../utils/commonFunctions";
import { DownloadOutlined } from "@ant-design/icons";
import axios from "axios";

const columns = [
  {
    title: "First Name",
    dataIndex: "first_name",
    key: "first_name",
    width: 180,
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
    key: "last_name",
    width: 180,
  },
  {
    title: "Photo",
    dataIndex: "photo",
    key: "photo",
    width: 90,
    render: (text) =>
      text ? (
        <Image src={URLS.lost_photo_base_url + text} alt="photo" width="60" />
      ) : (
        "-"
      ),
  },
  {
    title: "Gender",
    dataIndex: "gender",
    key: "gender",
  },
  {
    title: "DOB",
    dataIndex: "dob",
    key: "dob",
    render: (dob) => new Date(dob).toLocaleDateString(), // Formats the date
  },
  {
    title: "Age",
    dataIndex: "age",
    key: "age",
  },
  {
    title: "Height",
    dataIndex: "height",
    key: "height",
  },
  {
    title: "Complexion",
    dataIndex: "complexion",
    key: "complexion",
    width: 150,
  },
  {
    title: "Disability",
    dataIndex: "disability",
    key: "disability",
  },
  {
    title: "Health Condition",
    dataIndex: "disability_desc",
    key: "disability_desc",
    width: 190,
  },
  {
    title: "Any Mark",
    dataIndex: "any_mark",
    key: "any_mark",
  },
  {
    title: "Attire",
    dataIndex: "attire",
    key: "attire",
  },
  {
    title: "Lost Place",
    dataIndex: "lost_place",
    key: "lost_place",
  },
  {
    title: "Language Spoken",
    dataIndex: "language_spoken",
    key: "language_spoken",
    width: 150,
  },

  {
    title: "Country",
    dataIndex: "country",
    key: "country_id",
    render: (obj) => {
      return obj?.name || "-";
    },
  },
  {
    title: "State",
    dataIndex: "state",
    key: "state_id",
    render: (obj) => {
      return obj?.name || "-";
    },
  },
  {
    title: "City",
    dataIndex: "city",
    key: "city_id",
    render: (obj) => {
      return obj?.name || "-";
    },
  },
  {
    title: "Tehsil",
    dataIndex: "taluka",
    key: "taluka",
  },
  {
    title: "Police Station/Post Office",
    dataIndex: "police_station",
    key: "police",
    width: "300",
  },
  {
    title: "Village",
    dataIndex: "village",
    key: "village",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    width: 300,
  },
  {
    title: "Doc 1",
    dataIndex: "supporting_doc_1",
    key: "supporting_doc_1",
    width: 90,
    render: (text) =>
      text ? (
        <Image src={URLS.lost_photo_base_url + text} alt="photo" width="60" />
      ) : (
        "-"
      ),
  },
  {
    title: "Doc 2",
    dataIndex: "supporting_doc_2",
    key: "supporting_doc_2",
    width: 90,
    render: (text) =>
      text ? (
        <Image src={URLS.lost_photo_base_url + text} alt="photo" width="60" />
      ) : (
        "-"
      ),
  },
  {
    title: "Relation",
    dataIndex: "relation",
    key: "relation",
  },
  {
    title: "Complainant Name",
    dataIndex: "complainant_name",
    key: "complainant_name",
    width: 160,
  },
  {
    title: "Complainant Mobile ",
    dataIndex: "complainant_mob_number",
    key: "complainant_mob_number",
    width: 170,
  },
  {
    title: "Found",
    dataIndex: "isFound",
    key: "isFound",
    fixed: "right",
    width: 75,
    render: (isFound) =>
      isFound ? (
        <span className="flex  bg-green-300 text-green-700 w-fit font-semibold p-1">
          Yes
        </span>
      ) : (
        <div className="p-1 w-fit bg-red-300 text-red-700 font-semibold">
          No
        </div>
      ),
  },

  // {
  //   title: "Action",
  //   dataIndex: "action",
  //   key: "action",
  //   width: 100,
  //   fixed: "right",
  // },
];

const SearchLostPerson = () => {
  const [searchQuery, setSearchQuery] = useState();
  const [searchQueryFound, setSearchQueryFound] = useState();
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const outlet = useOutletContext();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const navigate = useNavigate();

  const [form] = Form.useForm();
  const dict = langingPage;
  const lang = outlet[1];

  useEffect(() => {
    if (searchQuery) {
      const fields = form.getFieldsValue();
      delete fields["complainant_mob_number"];
      delete fields["complainant_name"];

      for (const key in fields) {
        if (!fields[key]) delete fields[key];
      }

      setSearchQueryFound(generateSearchQuery(fields));
    }
  }, [searchQuery, form]);

  const dispatch = useDispatch();

  const actionFun = (el) => {
    return (
      <div
        className="border-none border flex w-10 p-1 justify-center items-center"
        onClick={() => {
          delete el["action"];
          dispatch(setUpdateSearchLostEl(el));
          navigate("/lost-person-registration/" + el._id);
        }}
      >
        <span className="justify-center items-center">
          <FaUserEdit className="text-2xl text-orange-400" />
        </span>
      </div>
    );
  };

  const [maxCount, setMaxCount] = useState({ lost: 0, found: 0 });

  useEffect(() => {
    const token = localStorage.getItem("sessionToken");
    const permissions = localStorage.getItem("permissions");

    if (token && permissions) {
      const searchPermissions = JSON.parse(permissions);
      for (const el of searchPermissions) {
        if (
          (el.module_.name === "lost_person" ||
            el.module_.name === "found_person") &&
          el.update
        ) {
          setCanEdit(true);
        }

        if (
          (el.module_.name === "lost_person" ||
            el.module_.name === "found_person") &&
          el.delete
        ) {
          setCanDelete(true);
        }
      }
    }
  }, []);

  const [loading, setLoading] = useState(false);

  // ----------------- Delete Functionality ----------------
  const [isDeleted, setIsDeleted] = useState(false);

  const handleDelete = async (url) => {
    try {
      const response = await axios.delete(URLS.base_url + url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("sessionToken"),
        },
      });
      if (response.data.status === "success") {
        message.info("Deleted Successfully!");
        setIsDeleted(!isDeleted);
      }
    } catch (error) {
      message.info(error.response.data.message);
    }
  };
  return (
    <div>
      <CommonDivider label={dict.lost_and_found_search[lang]}></CommonDivider>
      <CommonSearchForm
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        form={form}
        fields={[
          { name: "complaint_number", label: dict.ticket_number[lang] },
          { name: "first_name", label: dict.first_name[lang] },
          { name: "last_name", label: dict.last_name[lang] },
          { name: "age", label: dict.age[lang] },
        ]}
        dropFields={
          ([
            {
              name: "gender",
              placeholder: dict.select_gender[lang],
              options: [
                { value: "M", label: dict.male[lang] },
                { value: "F", label: dict.female[lang] },
              ],
            },
          ],
          [
            {
              name: "is_reported_through_1920",
              placeholder: dict.is_reported_through_1920[lang],
              options: [
                { value: true, label: dict.yes[lang] },
                { value: false, label: dict.no[lang] },
              ],
            },
          ])
        }
      ></CommonSearchForm>
      {JSON.parse(localStorage.getItem("mis")) && (
        <div className="-mb-1 w-full flex justify-end gap-3 flex-wrap">
          <Button
            className="bg-violet-200 font-semibold"
            onClick={() => {
              let url = URLS.itervalReports + "&isLost=Lost&" + searchQuery;
              setLoading(true);
              downloadLostReport(setLoading, "Lost_Hourly_Data_Report", url);
            }}
            loading={loading}
          >
            <DownloadOutlined></DownloadOutlined>
            Lost Hourly Data Report{" "}
          </Button>

          <Button
            className="bg-violet-200 font-semibold"
            onClick={() => {
              let url = URLS.itervalReports + "&isLost=Found&" + searchQuery;
              setLoading(true);
              downloadLostReport(setLoading, "Found_Hourly_Data_Report", url);
            }}
            loading={loading}
          >
            <DownloadOutlined></DownloadOutlined>
            Found Hourly Data Report{" "}
          </Button>

          <Button
            className="bg-violet-200 font-semibold"
            onClick={() => {
              let url = URLS.pendingClosed + searchQuery;
              setLoading(true);
              downloadLostReport(
                setLoading,
                "Open and Closed Tickets Report",
                url
              );
            }}
            loading={loading}
          >
            <DownloadOutlined></DownloadOutlined>
            Open and Closed Tickets Report
          </Button>
          <Button
            className="bg-violet-200 font-semibold"
            onClick={() => {
              setLoading(true);
              downloadLostReport(
                setLoading,
                "Lost Report",
                URLS.lostReports + searchQuery
              );
            }}
            loading={loading}
          >
            <DownloadOutlined></DownloadOutlined>
            Lost Report
          </Button>

          <Button
            className="bg-violet-200 font-semibold"
            onClick={() => {
              setLoading(true);
              downloadLostReport(
                setLoading,
                "Found Report",
                URLS.foundReport + searchQuery
              );
            }}
            loading={loading}
          >
            <DownloadOutlined></DownloadOutlined>
            Found Report
          </Button>
        </div>
      )}
      <div className="flex w-full gap-3 h-full" key={isDeleted}>
        <CommonSearch
          title={dict.lost_persons[lang]}
          imageBaseUrl={URLS.lost_photo_base_url}
          background={"orange-300"}
          uri="searchLostPerson"
          reactBaseUrl="lost-found-search"
          columns={columns}
          extraParams={searchQuery}
          form={form}
          scroll={{ x: 4000, y: 500 }}
          actionFun={actionFun}
          setIsModalVisible={setIsModalVisible}
          visible={false}
          setTotalCount={(val) => {
            setMaxCount((prev) => {
              return { ...prev, lost: val };
            });
          }}
          // isFoundHandover={"&isFound=false"}
          isFoundHandover={"&"}
          canEdit={canEdit}
          canDelete={canDelete}
          handleDelete={handleDelete}
        ></CommonSearch>

        <CommonSearch
          title={dict.found_persons[lang]}
          imageBaseUrl={URLS.found_photo_base_url}
          background={"green-300"}
          uri="searchFoundPerson"
          reactBaseUrl="lost-found-search"
          columns={columns}
          extraParams={searchQueryFound}
          form={form}
          scroll={{ x: 4300, y: 400 }}
          actionFun={actionFun}
          setIsModalVisible={setIsModalVisible}
          setTotalCount={(val) =>
            setMaxCount((prev) => {
              return { ...prev, found: val };
            })
          }
          totalDocsInput={
            maxCount.found > maxCount.lost ? maxCount.found : maxCount.lost
          }
          isFoundHandover={"&"}
          // isFoundHandover={"&isHandover=No"}
          canEdit={canEdit}
          canDelete={canDelete}
          handleDelete={handleDelete}
        ></CommonSearch>
      </div>
      <DataTableModal
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
      ></DataTableModal>
    </div>
  );
};

export default SearchLostPerson;
