import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import optionsMaker from "../utils/optionMaker";
import { langingPage } from "../utils/dictionary";
import { useOutletContext } from "react-router";

const CountryStateCity = ({
  form,
  country_id,
  state_id,
  city_id,
  setCityId = () => {},
}) => {
  const [selectedCountryId, setSelectedCountryId] = useState();
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedStateId, setSelectedStateId] = useState();
  const [villageOptions, setVillageOptions] = useState([]);
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];

  useEffect(() => {
    optionsMaker(
      "country",
      "name",
      "id",
      setCountryOptions,
      "?sort=name&limit=300"
    );
  }, []);

  useEffect(() => {
    if (countryOptions.length > 0) {
      const defaultCountryId = 76;
      setSelectedCountryId(defaultCountryId);
      form.setFieldsValue({ country_id: defaultCountryId });
    }
  }, [countryOptions, form]);

  useEffect(() => {
    if (selectedCountryId) {
      optionsMaker(
        "state",
        "name",
        "id",
        setStateOptions,
        "/" + selectedCountryId + "?sort=name&limit=300"
      );
    }
  }, [selectedCountryId]);

  useEffect(() => {
    if (selectedStateId) {
      optionsMaker(
        "city",
        "name",
        "id",
        setVillageOptions,
        "/" + selectedStateId + "?sort=name&limit=300"
      );
    }
  }, [selectedStateId]);

  useEffect(() => {
    if (countryOptions.length && country_id) {
      setSelectedCountryId(country_id);
    }
  }, [country_id, countryOptions]);

  useEffect(() => {
    if (stateOptions.length && state_id) {
      setSelectedStateId(state_id);
      form.setFieldsValue({ state_id });
    }
  }, [stateOptions, state_id]);

  useEffect(() => {
    if (villageOptions.length && city_id) {
      form.setFieldsValue({ city_id });
    }
  }, [form, villageOptions, city_id]);

  return (
    <>
      <Form.Item
        label={<div className="font-semibold">{dict.select_country[lang]}</div>}
        name="country_id"
        className="mb-4"
      >
        <Select
          allowClear
          size={"small"}
          showSearch
          placeholder={dict.select_country[lang]}
          optionFilterProp="children"
          onChange={(val) => setSelectedCountryId(val)}
          className="rounded-none"
        >
          {countryOptions.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label={<div className="font-semibold">{dict.state[lang]}</div>}
        name="state_id"
        className="mb-4"
      >
        <Select
          size={"small"}
          showSearch
          allowClear
          placeholder={dict.select_state[lang]}
          optionFilterProp="children"
          onChange={(val) => setSelectedStateId(val)}
          className="rounded-none"
        >
          {stateOptions.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label={<div className="font-semibold">{dict.city[lang]}</div>}
        name="city_id"
        className="mb-4"
      >
        <Select
          size={"small"}
          showSearch
          allowClear
          placeholder={dict.select_city[lang]}
          className="rounded-none"
          optionFilterProp="children"
          onChange={(value) => setCityId(value)}
        >
          {villageOptions.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export default CountryStateCity;
